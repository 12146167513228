<template>
	<div class="open-account-bank">
		
		<h1 class="h1_title">USDT</h1>
		
		<van-form @submit="nextStep()" :label-width="70">

			<h4 class="h4_title">USDT{{translate('account_information')}}</h4>

			<van-field
				readonly
				clickable
				:label="translate('main_network_type')"
				:value="indexType > -1 ? types[indexType].text : ''"
				:placeholder="translate('please_select_main_network_type')"
				@click="showType = true"
				:rules="rules.type"
				required
			/>
			<van-popup v-model="showType" round position="bottom">
				<van-picker
					:title="translate('main_network_type')"
					show-toolbar
					:columns="types"
					:default-index="indexType"
					@cancel="showType = false"
					@confirm="onConfirmType"
				/>
			</van-popup>
			
			<van-field 
				v-model="formItem.address" 
				:label="translate('usdt_address')" 
				:placeholder="translate('please_enter')"
				required
				:rules="[{ required: true, message: translate('please_enter_address') }]"
				/>
			
			<div class="common_block">
				<van-button type="warning" block round native-type="submit">{{translate('usdt_submit')}}</van-button>
			</div>
		</van-form>

		
	
	</div>
</template>

<script>
	export default {
		name: 'account_usdt',
		data() {
			return {
				formItem: {
					address: "",
					type: "3",
				},

				rules: {
					type: [
						{
							required: true,
							message: this.translate('please_select_main_network_type'),
							trigger: 'onBlur'
						}
					],
					address: [
						{
							required: true,
							message: this.translate('please_enter_address'),
							trigger: 'onBlur'
						}
					]
				},

				token: "",
				
				indexType: -1,
				showType: false,

				resourceURL: this.$axios.defaults.resourceURL,
								
				disabledSubmit: true,
				
				types: [],	
			}
		},
		mounted() {
			
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.token = this.$route.query.token
							
				this.$axios({
					method: 'post',
					url: 'account_open/methods', 
					data: {
						token: this.token
					},
				}).then ((res) => {
					if (res.success) {
						this.types = res.data

						this.init()
					} else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})

				
			}
		},
		methods: {
			init(){
				this.$axios({
					method: 'post',
					url: 'account_open/info',
					data: {
						token: this.token
					}
				}).then ((res) => {
					if (res.success) {

						if (res.data.status == 2) {
							//	待审核
							this.$router.replace('/account/success')
						} else if (res.data.status == 3) {
							//	不通过
							this.$router.replace('/account/fail?token=' + this.token)
						} else {
							
							var usdt_data = res.data.usdt_data

							if (usdt_data.length != 0) {
								this.formItem.address = usdt_data.address ? usdt_data.address : ""
								
								this.formItem.type = usdt_data.type ? usdt_data.type : ""

								this.types.forEach((item, key) => {
									if(item.type == usdt_data.type){
										this.indexType = key
									}								
								})
							} else {
								this.formItem.type = this.types[0]['type']
							}

						}

					}else{
						this.$toast.fail(res.error_text)
					}
				})

				
			},

			onConfirmType(value, index) {
				console.log(value)
				if (value) {
					this.indexType = index

					this.formItem.type = value.type
				}

				this.showType = false
			},
			nextStep(){
				
				this.$axios({
					method: 'post',
					url: 'account_open/update',
					data: {
						token: this.token,
						usdt_data: JSON.stringify(this.formItem)
					}
				}).then ((res) => {
					if (res.success) {
						this.$router.push('/account/success')
					}else{
						this.$toast.fail(res.error_text)
					}
				})
				
			}
		},
	}
</script>